import { n as normalizeComponent } from "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "after-close": _vm.afterModalClose,
            "destroy-on-close": "",
            "title": "Create Financial Year"
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submitModal);
                  }
                }
              }, [_vm._v(" Create ")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Financial Year Name"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("year", $event);
            }
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateFinancialYear",
  inject: ["crud", "redirectRoute"],
  data() {
    return {
      visible: false,
      createForm: {}
    };
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    },
    cancel() {
      this.visible = false;
    },
    storeValue(resourceKey, value) {
      const createForm = this.createForm;
      this.createForm = {
        ...createForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    async submitModal() {
      await this.crud.submitEntity("create");
      await this.crud.fetchList();
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateFinancialYear = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "fas.financials",
      "api-url": _vm.apiUrl,
      "redirect-route": "/fixed-asset-register"
    }
  }, [_c("create-financial-year")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { CreateFinancialYear },
  data() {
    return {
      CreateFinancialYear,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
